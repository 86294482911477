/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ChartsWidget2,
  ListsWidget3,
  StatisticsWidget5,
  TablesWidget10,
} from '../../../_metronic/partials/widgets'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import {getNotificationData} from "../../modules/auth/core/_requests";
import moment from "moment";
import { getDashboardData } from './core/_requests'
import { REQUEST_COLOR_DATA } from '../../../global.d'
import clsx from 'clsx'
import dateFormat from "dateformat";
import { Link } from 'react-router-dom'

const DashboardPage: FC = () => {

  const [loading, setLoading] = useState(false);
  const [defaultAlerts, setDefaultAlerts] = useState([]);
  const [recentRequest, setRecentRequest] = useState([]);
  const [requestCardData, setRequestCardData]:any = useState({});
  const [requestGraphData, setRequestGraphData]:any = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
        setLoading(true)
        try {
            const response: any = await getNotificationData();
           
            if (response.status && response.status !== null) {
              setDefaultAlerts(response.data.data);
            }
        } catch (error) {
        } finally {
          setLoading(false)
        }
    };
    const fetchDashboard = async () => {
      setLoading(true)
      try {
          const response: any = await getDashboardData();
          if (response.responseData && response.responseData.request !== null) {
            setRecentRequest(response.responseData.request.data);
            const {approvedCount, cancelledCount, inReviewCount, totalRequest}:any = response.responseData.request
            setRequestCardData({approvedCount, cancelledCount, inReviewCount, totalRequest});
            setRequestGraphData(response.responseData.graph.graphData);
          }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    };
    fetchNotifications();
    fetchDashboard();
  }, []);


  return(<>
    <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='bi bi-card-list'
            color='info'
            iconColor='white'
            title={requestCardData?.totalRequest}
            description='Total Architectural Reviews Request'
            titleColor='white'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='bi bi-card-checklist'
            color='success'
            iconColor='white'
            title={requestCardData?.approvedCount}
            titleColor='white'
            description='Total Approved Requests'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='bi bi-tools'
            color='warning'
            iconColor='white'
            title={requestCardData?.inReviewCount}
            titleColor='white'
            description='Total Under Review Request'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='bi bi-clipboard2-x'
            color='danger'
            iconColor='white'
            title={requestCardData?.cancelledCount}
            titleColor='white'
            description='Total Cancelled Request'
            descriptionColor='white'
          />
        </div>
    </div>
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Requests</span>
        </h3>
        <div className='card-toolbar'>
          <Link
            to={`/request`}
            className='btn btn-primary'
          >
            View All
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>CASE ID</th>
                <th className='min-w-140px'>TITLE</th>
                <th className='min-w-120px text-end'>SUBMITTED BY</th>
                <th className='min-w-100px text-end'>STATUS</th>
                <th className='min-w-100px text-end'>CREATED AT</th>
                <th className='min-w-100px text-end'>ACTION</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {recentRequest.map((request:any, index) => (
              <tr key={index}>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    #{request.case_id}
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {request.request_title}
                  </a>
                </td>
                <td className='text-end'>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {request?.member?.first_name+' '+request?.member?.last_name}
                  </span>
                </td>
                <td className='text-end'>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  <div className={clsx('badge fw-bolder badge-'+REQUEST_COLOR_DATA[request.current_process_step]?.color)}>
                    {REQUEST_COLOR_DATA[request.current_process_step]?.title}
                  </div>
                  </span>
                </td>
                <td className='text-end'>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {request?.created_at ? dateFormat(new Date(request.created_at), "mmm dS, yyyy h:ss TT") : ""}
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                  <Link
                    to={`/request/${request?.id}/details`}
                    className='btn btn-icon btn-bg-light btn-active-color-primary'
                  >
                    <i className="bi bi-eye fs-1 fw-bold text-bold"></i>
                  </Link>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-6'>
        <div className={`card`}>
          <div className='card-header border-0'>
            <h3 className='card-title fw-bold text-dark'>Recent Notifications</h3>
          </div>
          <div className='card-body pt-2'>
              <div className='scroll-y mh-375px my-5'>
                {defaultAlerts.map((alert:any, index) => (
                  <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='mb-0 me-2'>
                        <a className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                          {alert.title}
                        </a>
                        <div className='text-gray-400 fs-7'>{alert.body}</div>
                      </div>
                    </div>

                    <span className='badge badge-light fs-8'>{moment(alert.created_at).fromNow()}</span>
                  </div>
                ))}
              </div>
          </div>
        </div>
      </div>
      <div className='col-xl-6'>
        { requestGraphData &&
          <ChartsWidget2 className='h-100' chartData={requestGraphData} />
        }
      </div>
    </div>
    {/* end::Row */}
  </>)
}


const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
