/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {
  KTIcon,
  toAbsoluteUrl,
} from '../../../helpers'
import {getNotificationData} from "../../../../app/modules/auth/core/_requests";
import moment from "moment";

const HeaderNotificationsMenu: FC = () => {
  
  const [loading, setLoading] = useState(false);
  const [defaultAlerts, setDefaultAlerts] = useState([]);
  useEffect(() => {
    const fetchAcknowledgement = async () => {
        setLoading(true)
        try {
            const response: any = await getNotificationData();
           
            if (response.status && response.status !== null) {
              setDefaultAlerts(response.data.data);
            }
        } catch (error) {
        } finally {
          setLoading(false)
        }
    };
    fetchAcknowledgement();
  }, []);

  return (<div
    className='menu menu-sub menu-sub-dropdown menu-column w-400px w-lg-400px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>
    </div>

    <div className='tab-content'>
      <div className='tab-pane show active' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultAlerts.map((alert:any, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    {alert.title}
                  </a>
                  <div className='text-gray-400 fs-7'>{alert.body}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>{moment(alert.created_at).fromNow()}</span>
            </div>
          ))}
        </div>

        {/* <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div> */}
      </div>

      
    </div>
  </div>)
}

export {HeaderNotificationsMenu}

