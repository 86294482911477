import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { IAcknowledgementCreate } from "./core/_models";
import { deleteAcknowledgement, getAcknowledgementsList } from "./core/_requests";
import TableSkeleton from "../../common/Skeleton/TableSkeleton";
const AcknowledgementList: FC = () => {
    const [acknowledgement, setAcknowledgement] = useState<IAcknowledgementCreate[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const getAck = async () => {
            setLoading(true)
            try {
                const response: any = await getAcknowledgementsList();
                setAcknowledgement(response.data);
            } catch (error) {
                setLoading(false);
                // console.error('Failed to fetch acknowledgements:', error);
                // toast.error('Failed to fetch acknowledgements. Please try again.');
            } finally {
                setLoading(false);
            }
        };
        getAck();
    }, []);
    const deleteAck = (ackId: number | undefined) => {
        if (ackId === undefined) {
            toast.error('Invalid Acknowledgement ID.');
            return;
        }
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // try {
                //     await deleteAcknowledgement(ackId);
                //     toast.success("Acknowledgement deleted successfully.");
                //     setAcknowledgement(prevAck => prevAck.filter(ack => ack.id !== ackId));
                // } catch (error) {
                //     toast.error("Failed to delete acknowledgement. Please try again.");
                // }
                const response = await deleteAcknowledgement(ackId);
                if (response) {
                    toast.success("Acknowledgement deleted successfully.");
                    setAcknowledgement(prevAck => prevAck.filter(ack => ack.id !== ackId));
                }
            }
        })
    }
    return (
        <>
            <div className="card mb-5 mb-xl-8">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-2 mb-1">Acknowledgement</span>
                    </h3>
                    <div className="card-toolbar" data-bs-placement="top" data-bs-trigger="hover">
                        <Link to={"/acknowledgement/create"} type="button" className="btn btn-primary">
                            <i className="ki-duotone ki-plus fs-3" /> Add Acknowledgement
                        </Link>
                    </div>
                </div>
                <div className="card-body py-3">
                    <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                                <tr className="fw-bold text-muted">
                                    <th className="min-w-150px">Title</th>
                                    <th className="min-w-150px text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ?
                                    (
                                        <TableSkeleton rows={10} columns={2} height={35} />
                                    ) :
                                    acknowledgement && acknowledgement.length > 0 ? (
                                        acknowledgement.map((ack, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start flex-column">
                                                            <Link to={`/acknowledgement/${ack.id}/edit`}> <p className="text-dark text-hover-primary fs-6" dangerouslySetInnerHTML={{ __html: ack?.title }}></p></Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end flex-shrink-0">

                                                        <Link to={`/acknowledgement/${ack.id}/edit`} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                            <i className="ki-duotone ki-pencil fs-3"><span className="path1" /><span className="path2" /></i>
                                                        </Link>
                                                        <button onClick={() => deleteAck(ack.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                            <i className="ki-duotone ki-trash fs-3"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))

                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className='d-flex fw-bold text-center fs-6 mt-5 w-100 align-content-center justify-content-center'>
                                                    No records have been added yet.
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export { AcknowledgementList }